<template>
  <!--涉及到多级路由参数-->
  <div class="page">

    <!--工具按钮集-->
    <div class="button-bar">
      <el-button
          type="primary"
          @click="submit()"
          :plain="true"
          size="mini"
          :disabled="ui.submitDisabled">
        <i class="fa fa-search i-gap"></i><span>立即查询</span>
        <span
            v-if="ui.count!=6 && ui.count!==0"
            class="timer"> {{ui.count}}
        </span>
      </el-button>
      <el-button
          type="warning"
          @click="resetForm('form')"
          :plain="true" size="mini">
        <i class="fa fa-undo"></i> 重置
      </el-button>
    </div>


    <!--表单滚动区域-->
    <div class="form-scroll">

      <!--字典按钮集-->
      <div class="dict-bar" v-if="lables && lables.length>0">
        <el-button
            v-for="(item,index) in lables"
            @click="lable(item)"
            type="primary"
            :plain="true"
            size="mini"
            class="uni-tag">
          {{item.dictLabel}}
        </el-button>
        <el-button
            @click="lable()"
            type="primary"
            size="mini"
            :plain="true">
          所有
        </el-button>
      </div>

      <!--表单-->
      <el-form ref="form" :model="form" :label-width="ui.labelWidth">

        <template v-for="(item,index) in caption.colModel"
                  v-if="!item.hidden &&
					item.search!=false &&
					item.formatter!='datetime' &&
					item.formatter!='date' &&
					item.formatter!='time' &&
					item.formatter!='file_url' &&
					item.formatter!='header_url' &&
					item.formatter!='image_url' &&
					item.formatter!='fa_icon' &&
					item.formatter!='password' &&
					item.formatter!='logo' ||
					item.formatter=='seek_date_mark'">

          <el-form-item
              :label="item.comment"
              :required="item.required"
              :show-message="false"
              :prop="item.name">

            <template
                v-if="item.formatter=='number' || item.formatter=='datanumber' || item.formatter=='money'">
              <el-input-number
                  v-model="form[item.name]"
                  size="small"
                  auto-complete="new-password"
                  autocomplete="off">
              </el-input-number>
            </template>

            <template
                v-else-if="item.formatter=='checkbox' || item.formatter=='boolean' || item.formatter=='check_mark'">
              <el-switch
                  @change="change($event,item)"
                  :active-value="1 || true"
                  v-model="form[item.name]">
              </el-switch>
            </template>

            <template v-else-if="item.formatter=='seek_date_mark'">

              <div class="select-datetime">
                <el-date-picker
                    v-model="conditionAttach[item.name+'_startDateInput']"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="起始日期"
                    class="datetime"
                    type="datetime"
                    clearable>
                </el-date-picker>
                -至-
                <el-date-picker
                    v-model="conditionAttach[item.name+'_endDateInput']"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="终止日期"
                    class="datetime"
                    type="datetime"
                    clearable>
                </el-date-picker>
              </div>

            </template>

            <template v-else>
              <el-input
                  v-model="form[item.name]"
                  :placeholder="'请输入'+item.comment"
                  auto-complete="new-password"
                  autocomplete="off"
                  clearable>
              </el-input>
            </template>

            <template v-if="item.linkTableName==''">
              <div class="fa-icon">
                <i
                    v-if="icon[item.formatter]!=null"
                    :class="'fa-icon fa '+icon[item.formatter]">
                </i>
                <i v-else class="fa-icon fa fa-file-text-o"></i>
              </div>
            </template>

          </el-form-item>

        </template>

        <!--占位-->
        <el-form-item v-show="ui.placeHolder" class="place-holder">
          &nbsp;
        </el-form-item>

      </el-form>
    </div>


  </div>
</template>

<script>

import {getTableCaption, list} from '@/api/public.js'

export default {

  name: "showSeekForm",
  props: ['caption'],

  data() {
    return {

      // 界面参数
      ui: {
        labelWidth: '120px',
        placeHolder: false,
        submitDisabled: false,
        count: 6,
      },

      // 字典标签
      lables: [],

      // 表单数据
      form: {},
      conditionAttach: {},

      // 后缀图标
      icon: {
        "text": "fa-file-text-o",
        "String": "fa-file-text-o",
        "money": "fa-rmb",
        "email": "fa-envelope-o",
        "password": "fa-key",
        "telephone": "fa-phone",
        "scan": "fa-camera-retro",
        "sex": "fa-user-o",
        "select": "fa-list",
        "remark": "fa-edit",
        "date": "",
        "datetime": "",
        "time": "",
        "seek_date_mark": "",
        "updatedate": "",
        "createdate": "",
        "mycreate": "fa-user-o",
        "number": "",
        "datanumber": "",
        "filepath": "",
        "checkbox": "",
        "boolean": "",
        "check_mark": "",
        "radio": "",
        "textarea": "",
        "editor": "",
      },

    }
  },
  created() {

    this.initParm();
    if (this.caption.tableName) {
      this.initCaption();
    }
    this.initLabels();

  },
  mounted() {

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }

  },
  updated() {
    // 表单占位符
    let element = document.querySelectorAll('.el-form-item:not(.place-holder)');
    if ((element.length % 2) == 1) {
      this.ui.placeHolder = true;
    }
  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {

    // 初始化参数
    initParm() {

      // 输入参数
      let e = this.$route.query;
      if (this.caption == undefined) {

        this.caption = {};
        for (let key in e) {
          if (key == "tableName") {
            let tableName = e[key];
            let tableAlias = tableName.replace(/_/g, "");
            tableAlias = tableAlias.toLowerCase();
            this.caption.tableAlias = tableAlias;
            this.caption[key] = tableName;
          } else {
            this.caption[key] = e[key];
          }
        }

      }

      this.setResize();
    },

    // 初始化数据格式
    initCaption() {

      getTableCaption(this.caption.tableName).then(res => {

        for (let key in res) this.caption[key] = res[key];
        this.$forceUpdate();

      });

    },

    // 初始化字典标签
    initLabels() {

      if (this.caption['dictType']) {

        let seekPo = {
          "pageNum": 1,
          "pageSize": 20,
          "condition": {
            "dictType": this.caption['dictType'],
          },
          "conditionAttach": {
            "customOper": [{
              "javaName": "dictType",
              "operator": "in",
            }],
          },

        };

        list("wl_dict_data", seekPo).then((res) => {
          this.lables = res.list;
          this.$forceUpdate();
        });

      }
    },

    // 检测窗体变化
    setResize() {
      let width = document.documentElement.clientWidth;
      if (width < 768) {
        this.ui.labelWidth = '80px';
      } else {
        this.ui.labelWidth = '120px';
      }
    },
    // 标签方式查询，填充到表单
    lable(item) {

      this.form = {};
      this.conditionAttach = {};

      if (item) {

        let colModel = this.caption.colModel;
        for (let key in colModel) {
          let cm = colModel[key];
          if (item.dictType == cm["dictType"]) {
            this.form[cm["name"]] = item.dictCode;
            break;
          }
        }
      }

      this.submit();

    },
    // 提交
    submit() {

      // 倒计时
      //this.ui.submitDisabled = true;

      // 分段查询，参数不好控制
      if (this.caption["ocx"] == "seek") {
        this.$emit('deepSeek', this.form, this.conditionAttach);
      } else {
        window.parent.deepSeek(this.form, this.conditionAttach);
      }

      // 开启倒计时
      //this.startTimer();
    },

    // 倒计时
    startTimer() {
      this.timer = setInterval(() => {
        if (this.ui.count > 0) {
          this.ui.count--;
        } else {
          clearInterval(this.timer); // 倒计时结束，停止计时器
          this.ui.submitDisabled = false;
          this.ui.count = 6;
        }
      }, 1000);
    },

    // 重置表单
    resetForm() {
      this.form = {};
      this.conditionAttach = {};
      this.$forceUpdate();
    },

    // 选项变更
    change(event, item) {
      this.form[item["name"]] = event == 1 ? 1 : 0;
    },
  },
}
</script>

<style lang="scss">

/*--基础+加载动画--*/
html, body, #app, .page {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.page {
  background: url(../../static/images/bodyback.jpg);
}

@keyframes page-fade-in {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

// 滚动条样式
#app .form-scroll::-webkit-scrollbar {
  border-radius: 10px;
  background-color: #F5F5F5;
}

#app .form-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#app .form-scroll::-webkit-scrollbar-thumb {
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
  background-color: rgba(153, 153, 153, 0.05);

  background-image: url(../../static/images/icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

/*--按钮栏--*/
.button-bar {

  margin: 10px;
  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);

  .timer {

    margin-left: 5px;
    font-size: 12px;
    color: #dd524d;
    text-align: center;
  }
}

.form-scroll {

  margin: 0px 10px;
  padding: 40px 20px;

  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
  background-color: #FFF;
  height: calc(100% - 150px);
  overflow-y: auto;

  background-image: url(../../static/images/bg_1.png);
  background-repeat: no-repeat;
  background-position: right bottom;

  .dict-bar {

    padding:5px;
    margin-top:-15px;
    margin-bottom: 20px;
    background-color: #f5f5f5;

    border: 1px #f0f0f0 solid;
    border-radius: 5px;

    .uni-tag {
      margin: 5px;
    }
  }

  .fa-icon {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: -1px;

    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #000;
    opacity: 0.4;

    pointer-events: none;
  }

  .el-form-item {

    margin-bottom: 5px !important;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;

    /*--2行--*/
    float: left;
    width: 50%;

    .el-form-item__label {
      white-space: nowrap;
      word-break: break-all;
      overflow: auto;
    }

    /*--时间段--*/
    .select-datetime {
      position: absolute;
      z-index: 10;
      top: 0px;
      left: 0px;
      width: 100%;
      white-space: nowrap;
      word-break: break-all;
      overflow-x: auto;
      overflow-y: hidden;

      .datetime {
        /*
        width: 195px !important;
        */
      }
    }

  }

  textarea {
    position: absolute;
    z-index: 100;
    top: -40px;
    height: 40px;
  }

  textarea:focus {
    height: 105px;
  }

}

/*--alert弹框--*/
.el-message-box {
  max-width: 80%;
}

.i-gap {
  margin-right: 5px;
}

@media (max-width: 768px) {

  .el-form-item {
    float: none !important;
    width: auto !important;
  }
  .place-holder {
    display: none;
  }

}

</style>